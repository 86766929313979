export default class Knowledge {
  init(root) {
    this.root = root;
    this.input = this.root.querySelector('input[type="search"]');
    this.searchWrapper = this.root.querySelector('.knowledge-search');
    this.searchReset = this.root.querySelector('.knowledge-search-reset');
    this.suggestionsContainer = this.root.querySelector('.knowledge-search-suggestions');
    this.form = this.input.closest('form');
    this.resultElements = this.root.querySelectorAll('.knowledge-list .item-description, .knowledge-list .item-title')
    this.copyLinkElements = this.root.querySelectorAll('.knowledge-list .item-copylink')
    this.imageoverlayElements = this.root.querySelectorAll('.item-button-imageoverlay')
    this.addEventListeners();

    const searchTerm = this.input.value.toLowerCase();
    this.checkInput(searchTerm)
  }

  addEventListeners() {
    this.input.addEventListener('input', this.displayMatchingSuggestions);

    // Store the arrow function in a variable
    this.handleSuggestionClickHandler = (event) => this.handleSuggestionClick(event);
    this.suggestionsContainer.addEventListener('mousedown', this.handleSuggestionClickHandler);

    this.input.addEventListener('focus', this.addActiveClass);
    this.input.addEventListener('blur', this.removeActiveClass);

    this.searchReset.addEventListener('click', this.resetSearch);

    this.resultElements.forEach((item) => {
      item.addEventListener('click', this.expandItem)
    })
    this.copyLinkElements.forEach((item) => {
      item.addEventListener('click', this.copyLink)
    })

    this.imageoverlayElements.forEach((item) => {
      item.addEventListener('click', this.openImageOverlay)
    })
  }

  expandItem(event) {
    // event.target.closest('.item').querySelector('.item-description').classList.toggle('expanded')
    // event.target.closest('.item').querySelector('.item-topics').classList.toggle('is-visible')
    // event.target.closest('.item').querySelector('.item-info').classList.toggle('is-visible')
    event.target.closest('.item').classList.toggle('is-visible')
  }

  openImageOverlay(event) {
    let src = event.target.closest('.item').querySelector('.item-image-wrapper img').src;
    console.log(src)

    const overlay = document.createElement('div');
    overlay.className = 'imageoverlay';
    overlay.innerHTML = `
      <div class="imageoverlay-button-close">Zurück</div>
      <img src="${src}" class="imageoverlay-image"/>
    `;
    document.body.appendChild(overlay);
    const closeButton = overlay.querySelector('.imageoverlay-button-close');

    closeButton.addEventListener('click', () => {
      document.body.removeChild(overlay);
    });
  }

  copyLink(event) {
    event.preventDefault()
    let href = event.target.closest('.item-copylink').getAttribute('href');

    clearTimeout(this.linkCopyTimeout);
    let dialogLinkCopy = document.querySelector('.knowledge-copied-notification');
    navigator.clipboard.writeText(href).then(() => {
      dialogLinkCopy.classList.add('is-visible');
      if (this.dialogCitation) {
        this.dialogCitation.classList.remove('is-visible');
      }
      this.linkCopyTimeout = setTimeout(() => {
        dialogLinkCopy.classList.remove('is-visible');
      }, 1500);
    });
  }

  checkInput(searchTerm) {
    if(searchTerm != "") {
      this.displayReset()
    } else {
      this.hideReset()
    }
  }

  displayMatchingSuggestions = () => {
    const searchTerm = this.input.value.toLowerCase();
    const suggestions = this.suggestionsContainer.querySelectorAll('span');
    let suggestionAmount = 0;

    this.checkInput(searchTerm)

    suggestions.forEach((suggestion) => {
      suggestion.style.display = 'none';
    });

    suggestions.forEach((suggestion) => {
      const keyword = suggestion.innerText.toLowerCase();
      if(searchTerm.length > 1) {
        if (keyword.includes(searchTerm) && searchTerm != "") {
          suggestion.style.display = 'block';
          suggestionAmount++;
        }
      } else { //only one letter -> only look for first letter
        if (keyword.startsWith(searchTerm) && searchTerm !== "") {
          suggestion.style.display = 'block';
          suggestionAmount++;
        }
      }
    });

    console.log(searchTerm.length)

    if (suggestionAmount > 0 && searchTerm != "") {
      this.searchWrapper.classList.add('active-suggestions');
    } else {
      this.searchWrapper.classList.remove('active-suggestions');
    }
  };

  handleSuggestionClick = (event) => {
    const clickedSuggestion = event.target.closest('span');
    if (clickedSuggestion) {
      const clickedKeyword = clickedSuggestion.innerText;
      this.input.value = clickedKeyword;
      this.form.submit();
    }
  };


  addActiveClass = () => {
    this.searchWrapper.classList.add('active');
  }
  removeActiveClass = () => {
    this.searchWrapper.classList.remove('active');
  }

  resetSearch = () => {
    console.log('reset')
    this.input.value = "";
    this.form.submit();
  }

  displayReset() {
    this.searchReset.classList.add('is-visible');
  }

  hideReset() {
    this.searchReset.classList.remove('is-visible');
  }

  destroy() {
    this.input.removeEventListener('input', this.displayMatchingSuggestions);

    // Remove the event listener using the stored variable
    this.suggestionsContainer.removeEventListener('mousedown', this.handleSuggestionClickHandler);

    this.searchReset.removeEventListener('click', this.resetSearch);

    this.input.removeEventListener('focus', this.addActiveClass);
    this.input.removeEventListener('blur', this.removeActiveClass);
  }
}

import {map, clamp } from './../../utils/math.js';

export default class Team {
  init(root) {
      this.root = root
      this.profile = root.querySelector('.team-profile')
      this.description = root.querySelector('.team-profile-description')
      this.icon = root.querySelector('.team-profile-icon')
      this.columns = root.querySelectorAll('.team .team-column')

      this.render()
  }

  render() {
      console.log(this.root)
      this.columns.forEach((col) => {
        col.addEventListener('click', () => {
          //get information
          const cap = col.querySelector('figcaption').innerHTML;
          let url;
          if(col.querySelector('a')) {
            url = col.querySelector('a').getAttribute('href');
          }

          //update profile
          this.updateProfile(cap, url)

          //remove active states
          this.columns.forEach((el) => {
            el.classList.remove('is-active')
          })
          //add active state
          col.classList.add('is-active')
        })
      })
  }

  updateProfile(cap, url) {
    //no link
    this.description.innerHTML = cap;
    this.icon.classList.remove('is-visible')
    this.profile.setAttribute('href', '#');
    this.profile.classList.remove('has-link')

    //with link
    if(url) {
      this.description.innerHTML = cap;
      this.icon.classList.add('is-visible')
      this.profile.setAttribute('href', url);
      this.profile.classList.add('has-link')
    }
  }
}





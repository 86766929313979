export default class Hero {
    init(root) {
        this.root = root
        this.render()
        console.log('this.root')
        console.log(this.root)
    }

    render() {

    }
}

// import Swiper, {Pagination, Navigation} from 'swiper';
// import Swiper from 'swiper/swiper-bundle.min.js';
import 'swiper/swiper.min.css'
// import 'swiper/modules/navigation/navigation.min.css'


export default class MySwiper {
    init(root) {
      this.root = root
      this.slides =  this.root.querySelectorAll('.swiper-slide')
      this.videos =  this.root.querySelectorAll('video')
      // this.next = root.querySelector('.swiper-button-next')
      // this.prev = root.querySelector('.swiper-button-prev')

      let options = {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }

      if(this.root.classList.contains('formate-swiper')) {
        options = {
          direction: "vertical",
          speed: 1000,
          // parallax: true,
          slidesPerView: "auto",
          centeredSlides: true,
          // slidesPerView: 1,
          loop: true,
          spaceBetween: 0,
          autoplay: {
            delay: 8000,
            disableOnInteraction: true
          },
          mousewheel: {
            releaseOnEdges: false,
            thresholdDelta: 20,
            thresholdTime: 50,
          },
        }
      }

      if(this.root.classList.contains('swiper-block')) {
        options = {
          slidesPerView: "auto",
          centeredSlides: true,
          // loop: true,
          spaceBetween: 0,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        }
      }

      this.swiper = new Swiper(this.root, options);

      // this.swiper.on('slideChange', () => {
      //   this.playVideos()
      // });

      // this.swiper.on('slideChange', () => {
      //   this.swiper.pagination.render();
      //   this.swiper.pagination.update();
      // });

      console.log(this.videos)
      this.playVideos()

    }
    playVideos() {
      if (this.videos.length > 0) {
        this.videos.forEach(video => {
          video.play();
        })
      }
    }
    destroy() {
      this.swiper.destroy()
      console.log('destroyed this swiper')
    }
}

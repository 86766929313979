export const map = (n, start1, stop1, start2 = 0, stop2 = 1) => {
	return ((n-start1)/(stop1-start1))*(stop2-start2)+start2;
};

export const clamp = (number, min = 0, max = 1) => {
	return Math.max(min, Math.min(number, max));
}

export const loopback = (i, m = 100) => {
	//returns 1 while value is progressing, returns 0 if value is 0 or 1
	//based on triangle wave function
	i = clamp(i) //normalize value to 0-1
	return Math.ceil(Math.abs((i*(m)) % (m)) / m)
}

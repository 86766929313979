import {map, clamp } from './../../utils/math.js';

export default class Header {
    init(root, barba) {
      this.root = root
      this.calendarAlert = root.querySelector('.header-calendar')
      this.currentEvent = document.querySelector('.calendar .is-now')
      this.menu = root.querySelector('.menu')
      this.menuItems = root.querySelectorAll('.menu-item')
      this.backButton = root.querySelector('.menu-item.back')

      //show calendar 'now open'
      if(this.currentEvent) {
        this.calendarAlert.classList.add('is-visible')
      }

      //handle language switch
      this.language = root.querySelector('.header-language a')
      this.language.addEventListener('click', (e) => {
        barba.force(`${this.language.dataset.href}`)
      })

      this.backButton.addEventListener('click', (event) => {
        event.preventDefault();
        var parentPageURL = document.querySelector('.container').dataset.parentHref;
        window.location.href = parentPageURL
        // console.log(barba)
        // barba.go(parentPageURL)
      })

    }

    updateMenu(currentPage) {
      this.menu.classList.add('show-back-button') //removing it once again if one menu item is active

      // setTimeout(() => {
      //   var parentPageURL = document.querySelector('.container').dataset.parentHref;
      //   console.log(parentPageURL)
      //   if(parentPageURL) {
      //     this.backButton.href = parentPageURL
      //     console.log(this.backButton)
      //   }
      // }, 1000)

      //handle active menu items
      this.menuItems.forEach(item => {
        item.classList.remove('is-active')
        if(item.classList.contains(currentPage + '-template')) {
          item.classList.add('is-active')
          // console.log('is-active')
          this.menu.classList.remove('show-back-button')
        }
      });

      // console.log(currentPage)
    }

    render() {
    }
}





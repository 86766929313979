import {map, clamp } from './../../utils/math.js';

export default class Quickinfos {
    init(root) {
      this.root = root
      this.list = this.root.querySelector('.knowledge-list')
      this.items = this.list.querySelectorAll('.item')
      this.quickinfos = this.list.querySelectorAll('.knowledge-list-quickinfo')

      this.items.forEach((item, index) => {
        item.style.order = 10 + (index * 10);
      })

      //shuffle
      const quickinfosArray = Array.from(this.quickinfos);
      quickinfosArray.sort((a, b) => 0.5 - Math.random());
      this.quickinfos = quickinfosArray;

      //add order or hide quickinfo
      this.quickinfos.forEach((quickinfo, index) => {
        if(this.items.length / 10 > index + 1) {
          quickinfo.style.order = 100 + (index * 100);
        } else {
          quickinfo.style.display = 'none';
        }
      })

    }

}





import {map, clamp } from './../../utils/math.js';

export default class Intro {
    init(root) {
        this.root = root
        this.spots = root.querySelector('.intro-spots')
        this.lightbox = root.querySelector('.intro-lightbox')

        //Neckarinsel
        this.minLat = 48.800089788226735;
        this.maxLat = 48.801638085650616;
        this.minLong = 9.209820884133052;
        this.maxLong = 9.21204898902386;
        //area
        /*this.minLat = 48.3;
        this.maxLat = 49.0;
        this.minLong = 9.0;
        this.maxLong = 9.5;*/
        //hallschalg
        /*this.minLat = 48.811;
        this.maxLat = 48.81262;
        this.minLong = 9.20282;
        this.maxLong = 9.20697;*/
        this.imagesJSON
        //this.width = this.root.offsetWidth
        //this.height = this.root.offsetHeight
        this.render()
    }

    render() {
      //load json
      fetch("./intro-images/images.json")
      .then(response => {
         return response.json();
      })
      .then(jsondata => {
        this.imagesJSON = jsondata;
        this.createSpots()
      });
    }

    createSpots() {
      //console.log(this.width)
      this.imagesJSON.forEach(el => {
        //console.log(el)

        var spot = document.createElement('div');
        spot.className = 'spot';
        spot.style.left = `${100 - map(el.lat, this.minLat, this.maxLat, 0, 100)}%`
        spot.style.top = `${map(el.long, this.minLong, this.maxLong, 0, 100)}%`
        spot.setAttribute('data-name', el.name);
        this.spots.appendChild(spot);

        spot.addEventListener('mouseenter', () => {
          this.lightbox.src = `./intro-images/${el.name}`
          this.lightbox.style.display = "block"
        })
        spot.addEventListener('mouseleave', () => {
          this.lightbox.src = ""
          this.lightbox.style.display = "none"
        })
      })
    }

}





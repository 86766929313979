import '../scss/app.scss';

// Your JS Code goes here
import barba from '@barba/core';
import { gsap } from "gsap";
import Hero from '../components/hero/hero.js'
import Intro from '../components/intro/intro.js'
import MySwiper from '../components/swiper/swiper.js'
import Header from '../components/header/header.js'
import Team from '../components/team/team.js'
import Knowledge from '../components/knowledge/knowledge.js'
import Quickinfos from '../components/quickinfos/quickinfos.js'

let footerEl = document.querySelector(".footer");

let knowledge;

let headerEl = document.querySelector(".header");
let header = new Header();
let initalPage = document.querySelector('.container').dataset.barbaNamespace;
header.init(headerEl, barba)
header.updateMenu(initalPage)


let swipers = []

initComponents(document)

barba.init({
	preventRunning: true,
	debug: false,
	// debug: true,
	// logLevel: 'debug',
	transitions: [
	{
	  name: 'fromAndToKnowledge',
	  from: {
			namespace: ['wissen', 'knowledge']
	  },
	  to: {
			namespace: ['wissen', 'knowledge']
	  },
	  leave(data) {
      //hide footer on animation start
      footerEl.classList.add('is-hidden')
      return gsap.to(data.current.container.querySelector('.knowledge-list'), {
        opacity: 0,
        y: 50,
        duration: 0.2,
        delay: 0
      });
	  },
	  enter(data) {
      //data empty on leave –> could this update be done earlier?
      let currentPage = data.next.namespace;
      header.updateMenu(currentPage)
      initComponents(data.next.container)
      return gsap.from(data.next.container.querySelector('.knowledge-list'), {
        opacity: 0,
        y: 50,
        duration: 0.2,
        delay: 0.2,
        onStart: entering(data)
      });
	  },
    afterEnter(data) {
      //show footer after animation
      footerEl.classList.remove('is-hidden')
    }
	},
	{
	  name: 'fromFormate',
	  from: {
			namespace: ['formate', 'formats'],
	  },
	  to: {
			namespace: ['format', 'format'],
	  },
	  leave(data) {
      //hide footer on animation start
      // footerEl.classList.add('is-hidden')
      console.log('FORMATE TRANSITON')
      return gsap.to(data.current.container.querySelector('.swiper-slide-active img'), {
        width: '100%',
        maxWidth: '100vw',
        height: '100%',
        borderRadius: 0,
        duration: 1,
        delay: 0
      });
	  },
	  enter(data) {
      //data empty on leave –> could this update be done earlier?
      let currentPage = data.next.namespace;
      header.updateMenu(currentPage)
      initComponents(data.next.container)
      return gsap.from(data.next.container.querySelector('.swiper-slide h2'), {
        // opacity: 0,
        // y: 50,
        duration: 0.2,
        delay: 0.2,
        onStart: entering(data)
      });
	  },
    afterEnter(data) {
      //show footer after animation
      // footerEl.classList.remove('is-hidden')
    }
	},
	{ //last defined transition is the default:
		name: 'opacity-transition',
		leave(data) {
      //hide footer on animation start
      footerEl.classList.add('is-hidden')
		  return gsap.to(data.current.container, {
        opacity: 0,
        duration: 0.5,
        y: 200,
        delay: 0
		  });
		},
		enter(data) {
      //data empty on leave –> could this update be done earlier?
      let currentPage = data.next.namespace;
      header.updateMenu(currentPage)
      initComponents(data.next.container)

		  return gsap.from(data.next.container, {
        opacity: 0,
        duration: 0.5,
        y: 200,
        delay: 0.5,
        onStart: entering(data)
		  });
		},
    afterEnter(data) {
      // initComponents()
      //show footer after animation
      footerEl.classList.remove('is-hidden')
    }
	  }
	]
});


function entering(data) {
  //let currentPage = data.next.namespace;
  window.scrollTo(0, 0);
}

function spanFerkel(s) {
	if ( s ) {
		return `<mark>${
			s.split( '' ).map(
				c => ( c.toLowerCase() !== c ? `<sup>${c}</sup>` : `<sup>${c}</sup>` ),
			).join( '' )
				.split( ' ' )
				.join( '</mark> <mark>' )
		}</sup>`;
	}
	return '';
};

function initComponents(container) {

  //jump to calendar if id in url
  if(window.location.href.includes('#calendar')) {
    const calendar = document.getElementById("calendar")
    console.log(calendar)
    setTimeout(() => {
      calendar.scrollIntoView();
    }, 1000)
  }

  let heroEl = container.querySelector(".hero");
  if(heroEl) {
    let hero = new Hero();
    hero.init(heroEl)
  }

  let introEl = container.querySelector(".intro");
  if(introEl) {
    let intro = new Intro();
    intro.init(introEl)
  }

  let teamEl = container.querySelector(".team-wrapper");
  if(teamEl) {
    let team = new Team();
    team.init(teamEl)
  }

  let knowledgeEl = container.querySelector(".knowledge");
  if(knowledgeEl) {
    knowledge = new Knowledge();
    knowledge.init(knowledgeEl)
    let quickinfos = new Quickinfos();
    quickinfos.init(knowledgeEl)
  }

  swipers.forEach(swiper => {
    swiper.destroy()
  })
  let swipersEls = container.querySelectorAll(".swiper");
  swipers = [];
  swipersEls.forEach((el) => {
    let swiper = new MySwiper();
    swiper.init(el)
    swipers.push(swiper)
  })

  header.init(headerEl, barba)

  //animated headlines
  const allHeadlines = container.querySelectorAll('h2, .welcome-to, .offsetletters');
  allHeadlines.forEach((headline, i) => {
    headline.innerHTML = spanFerkel(headline.innerHTML)
    headline.querySelectorAll('sup').forEach((el) => {
      // el.style.transform = `translate3d(0, ${(Math.random() - 0.5) * 50}%, 0)`;
      let randomValue =  (Math.random() * 2 - 1) * 25;
      gsap.fromTo(el, {
        y: randomValue + "%",
      }, {
        y: -randomValue + "%",
        yoyo: true,
        repeat: -1,
        duration: 4,
        // delay: i * 0.1,
        ease: "power1.inOut"
      });
    })
  })

  //static headlines with offset letters
  // const offsetHeadlines = container.querySelectorAll('.offsetletters');
  // offsetHeadlines.forEach((headline, i) => {
  //   headline.innerHTML = spanFerkel(headline.innerHTML)
  //   headline.querySelectorAll('sup').forEach((el) => {
  //     el.style.transform = `translate3d(0, ${(Math.random() - 0.5) * 35}%, 0)`;
  //   })
  // })

}




barba.hooks.leave((data) => {
  if(knowledge) {
    knowledge.destroy();
  }
});
